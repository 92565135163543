.pagination {
  .page-item {
    .page-link {
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  &.pagination-separated {
    .page-item {
      margin-left: 2px;
      margin-right: 2px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child  {
        margin-right: 0;
      }
    }
  }
  &.pagination-rounded {
    .page-item {
      margin-right: 2px;
      margin-left: 2px;
      .page-link {
        @include border-radius(50px);
      }
    }
  }
}
