// Write your custom css/scss here
.table-wrapper {
    width: 100%;
    height: 204px;
    /* Altura de ejemplo */
    overflow: auto;
}

.table-wrapper table {
    border-collapse: separate;
    border-spacing: 0;
}

.table-wrapper table thead {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    left: 0;
}

.table-wrapper table thead th {
    background-color: #FFF;
}


/* .thead {
    z-index: 15;
}

.tbody {

} */

/* .table-wrapper thead,
.table-wrapper tbody {
    z-index: 15;
} */

/* .table-wrapper table thead th,
.table-wrapper table tbody td {
    border: 1px solid #000;
    background-color: #FFF;
} */

/**
 * CSS del fichero de agenda
*/
/* #customBtn {
    display: inline-block;
    background: white;
    color: #444;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
}

#customBtn:hover {
    cursor: pointer;
}

#customBtn:active {
    background: green;
}

button.selected {
    background: red;
}

span.label {
    font-family: serif;
    font-weight: normal;
}

span.icon {
    background: url('https://developers.google.com/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
}

span.icon2 {
    background: url('https://learn.microsoft.com/es-es/entra/identity-platform/media/howto-add-branding-in-apps/ms-symbollockup_mssymbol_19.png') transparent 10px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
}

span.buttonText {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: bold;
    padding-right: 0.5rem;
    // Use the Roboto font that is loaded in the <head>
    font-family: 'Roboto', sans-serif;
} */

/* Estilo de los select2 de la agenda */
#color-container {
    height: 21px;
    width: 21px;
    overflow: hidden;
}

#triangle-topleft {
    width: 0;
    height: 0;
    border-top: 10px solid gray;
    border-right: 10px solid transparent;
}

/* Estilos usados en la agenda para márgenes, pueden ser globales */
.mr-2 {
    margin-right: 0.5rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.thead_back td {
    top: 0px;
    position: sticky;
    background-color: black;
    color: #FFF;
}

.tbody-custom td {
    padding: 1px;
}
