// npm package: flatpickr
// github link: https://github.com/flatpickr/flatpickr

.form-control.flatpickr-input {
	background-color: $input-bg;
}

.flatpickr-day.selected, 
.flatpickr-day.startRange, 
.flatpickr-day.endRange, 
.flatpickr-day.selected.inRange, 
.flatpickr-day.startRange.inRange, 
.flatpickr-day.endRange.inRange, 
.flatpickr-day.selected:focus, 
.flatpickr-day.startRange:focus, 
.flatpickr-day.endRange:focus, 
.flatpickr-day.selected:hover, 
.flatpickr-day.startRange:hover, 
.flatpickr-day.endRange:hover, 
.flatpickr-day.selected.prevMonthDay, 
.flatpickr-day.startRange.prevMonthDay, 
.flatpickr-day.endRange.prevMonthDay, 
.flatpickr-day.selected.nextMonthDay, 
.flatpickr-day.startRange.nextMonthDay, 
.flatpickr-day.endRange.nextMonthDay {
	background: $primary;
	border-color: $primary;
}

.flatpickr-months {
	padding: 0 1rem;
	padding-top: 0.5rem;
}

.flatpickr-innerContainer {
  // padding: 0.5rem 1rem;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, 
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  
  left: 11px;
  right: auto !important;
  top: 8px;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, 
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  
  right: 11px;
  left: auto !important;
  top: 8px;
}

.flatpickr-months .flatpickr-prev-month:hover svg, 
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $primary;
}

.flatpickr-months .flatpickr-month {
  height: 42px;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  font-size: 1rem;
  border-radius: $input-border-radius;
  padding: .3rem .5rem;
}

.flatpickr-weekdays {
  padding: 0 10px;
}

.dayContainer {
  padding: 0 10px 10px;
}